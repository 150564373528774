import axios from 'axios'
import type { UserDecathlon, Addresses } from 'src/components/account/types'

type GetAddressDataParams = {
  currentAddress?: Addresses
  newAddress: HTMLFormElement | null
  userDecathlon: UserDecathlon | null
}

export function getAddressData({
  newAddress,
  userDecathlon,
  currentAddress,
}: GetAddressDataParams) {
  return currentAddress
    ? {
        ...currentAddress,
        postal_code: newAddress?.postalcode.value,
        complement: newAddress?.complement.value,
        street: `${newAddress?.street.value}, ${newAddress?.number.value}`,
        administrative_divisions: {
          district: newAddress?.neighborhood.value,
          province: newAddress?.state.value,
        },
        locality: newAddress?.city.value,
      }
    : {
        id: '',
        accuracy: '',
        administrative_divisions: {
          district: newAddress?.neighborhood.value,
          province: newAddress?.state.value,
        },
        complement: newAddress?.complement.value,
        country_code: 'BRA',
        firstname: userDecathlon?.getUserProfile.claims.given_name ?? '',
        lastname: userDecathlon?.getUserProfile.claims.family_name ?? '',
        is_favorite: true,
        locality: newAddress?.city.value,
        phone_number: userDecathlon?.getUserProfile.claims.phone_number ?? '',
        postal_code: newAddress?.postalcode.value,
        street: `${newAddress?.street.value}, ${newAddress?.number.value}`,
        type: 'SHIPPING',
        title: 'address',
        vtexId: '',
      }
}

export function cepAutoComplete(
  ev: React.ChangeEvent<HTMLInputElement>,
  Ref: HTMLFormElement | null,
  setReadOnly: React.Dispatch<
    React.SetStateAction<{
      neighborhood: boolean
      street: boolean
      state: boolean
      city: boolean
    }>
  >
) {
  try {
    const { value } = ev.target
    const cep = value?.replace(/[^0-9]/g, '')

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((cepData) => {
        if (cepData.erro) {
          setReadOnly({
            city: false,
            neighborhood: false,
            state: false,
            street: false,
          })

          if (Ref) {
            Ref.neighborhood.value = ''
            Ref.street.value = ''
            Ref.state.value = ''
            Ref.city.value = ''
          }

          return
        }

        if (Ref) {
          Ref.neighborhood.value = cepData.bairro
          Ref.street.value = cepData.logradouro
          Ref.state.value = cepData.uf
          Ref.city.value = cepData.localidade
        }

        setReadOnly({
          city: !!cepData.localidade,
          neighborhood: !!cepData.bairro,
          state: !!cepData.uf,
          street: !!cepData.logradouro,
        })
      })
      .catch((err) => console.error(err))

    return null
  } catch (error) {
    console.error('Error while fetching CEP data', error)
  }
}

type UpdateAdress = {
  action: 'update' | 'register'
  orderFormId: string
  address: Addresses
  userDecathlon: UserDecathlon | null
}

export async function updateOrRegisterAddress({
  action,
  orderFormId,
  address,
  userDecathlon,
}: UpdateAdress) {
  await axios.post(`/api/orderForm/update/shippingAddresses`, {
    orderFormId,
    address,
  })

  const endPoint = `/api/account/${
    action === 'update' ? 'updateAddress' : 'addAddress'
  }`

  try {
    await axios.post(endPoint, {
      address,
      email: userDecathlon?.getUserProfile.claims.email ?? '',
    })
  } catch (error) {
    console.error({ error })
  }
}

export function cepCodeMask(value: string) {
  if (!value) {
    return ''
  }

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{5})(\d)/, '$1-$2')

  return value
}

export function validadeCep(cepValue: string) {
  const cep = cepValue.replace(/\D/g, '')

  return cep.length === 8
}
