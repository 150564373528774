import { useEffect } from 'react'
import { Picture } from 'src/components/ui/Image'
import Link from 'src/components/ui/Link'
import { Button } from 'src/components/restructure/ui/Button/Button'
import './style.scss'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { Container } from 'src/components/department/Container'
import { useElementOnScreen } from 'src/hooks/useElementOnScreen'
import { makeViewPromotion } from 'src/utils/restructure/analytics/makeViewPromotion'
import { makePromoViewG3 } from 'src/utils/restructure/analytics/makePromoViewG3'
import { InView } from 'react-intersection-observer'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import Slider from 'src/components/ui/Slider/Slider'

import type {
  BannerOurBrandsType,
  BrandContent,
} from '../../types/banner-our-brands'

export default function BannerOurBrands(props: BannerOurBrandsType) {
  const { brands, locationOnPage, section, enableSession } = props
  const standardObject = getStandardObject()
  const skeletonStyles =
    'w-full h-[304px] restructure-tablet:h-[420px] restructure-small-desktop:h-[530px]'

  const { containerRef, isVisible, hasBeenViewed, setHasBeenViewed } =
    useElementOnScreen({
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

  const sendAmplitudeClickEvent = (brand: BrandContent, index: number) => {
    if (!locationOnPage || !section) {
      return
    }

    const featureObject = getFeatureObject({
      section,
      locationOnPage,
      name: brand?.image?.altImage ?? '',
    })

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': brand?.title?.text ?? '',
        'promotional element index': index ?? 0,
      },
    })
  }

  useEffect(() => {
    if (!isVisible || hasBeenViewed) {
      return
    }

    const promotions = brands.map(({ title, linkUrl, image }, index) => {
      return {
        promotion_id: linkUrl,
        promotion_name: title.text,
        creative_slot: image.desktop,
        index: index + 1,
      }
    })

    sendEvent(makePromoViewG3(promotions))
    sendEvent(makeViewPromotion(promotions))
    setHasBeenViewed(true)
  }, [isVisible, hasBeenViewed, setHasBeenViewed, brands])

  if (!enableSession) {
    return <></>
  }

  const sendPromoFeatureViewedEvent = (item: BrandContent) => {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name: item?.image.altImage ?? item.title.text ?? '',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  return (
    <Container className="!px-0">
      <div
        ref={containerRef}
        className=" bg-restructure-background-page relative block md:mx-auto lg:mx-auto"
      >
        <Slider
          title="Nossas marcas"
          itemQuantity={brands.length}
          skeletonStyles={skeletonStyles}
          hasScrollbar={false}
        >
          {brands.map((brand, index) => (
            <li key={`product-$${index}`}>
              <Link
                data-testid="ourBrand-item-carousel"
                href={brand.linkUrl}
                onClick={() => {
                  sendAmplitudeClickEvent(brand, index)
                  sendEvent(
                    makeEventTrack({
                      eventAction: `Nossas Marcas - banner ${brand.title.text}`,
                      eventPage: 'Home',
                    })
                  )
                }}
              >
                <InView
                  as="div"
                  threshold={0.7}
                  onChange={(inView) => {
                    if (inView) {
                      sendPromoFeatureViewedEvent(brand)
                    }
                  }}
                  triggerOnce
                  className="relative"
                >
                  <Picture
                    data-banner-type="Carousel Home"
                    aria-label={`Imagem nossa marca: ${brand.title.text}`}
                    sources={[
                      {
                        src: brand.image.tablet,
                        width: 240,
                        height: 420,
                        media: '(max-width: 899px)',
                      },
                      {
                        src: brand.image.desktop,
                        width: 302,
                        height: 530,
                        media: '(min-width: 900px)',
                      },
                    ]}
                    img={{
                      loading: 'lazy',
                      alt: brand.image.altImage,
                      title: brand.title.text,
                      'data-id': brand.title.text,
                      src: brand.image.mobile,
                      width: 139,
                      height: 243,
                      className:
                        'object-contain max-w-[139px] restructure-maxTablet:max-w-[240px] rounded-sm restructure-maxTablet:rounded-lg restructure-small-desktop:max-w-[302px]',
                    }}
                  />
                  <div className="flex justify-between absolute bottom-0 w-full p-3 items-center restructure-maxTablet:p-4 restructure-small-desktop:p-6 max-w-[139px] restructure-maxTablet:max-w-[240px] restructure-small-desktop:max-w-[302px]">
                    <h3 className="brand-title text-restructure-primary-inverted desktop-heading-title3 mobile-heading-title5">
                      {brand.title.text}
                    </h3>
                    <div>
                      <Button.Icon
                        name="ArrowRight"
                        width={32}
                        height={32}
                        color="#fff"
                        className="border-[1px] border-restructure-borderColor-primary rounded-[360px] p-2 restructure-maxMobile:hidden"
                      />
                    </div>
                  </div>
                </InView>
              </Link>
            </li>
          ))}
        </Slider>
      </div>
    </Container>
  )
}
