import { useEffect, useState } from 'react'
import { Modal } from 'src/components/common/Modal'
import { PlusIcon } from 'src/components/Icons/PlusIcon'
import { menuDataFallback } from 'src/components/common/Header/Menu/data/menuDataFallback'
import type { Level2, Level3SimpleListing } from 'src/graphql/queries/menu'
import { useMenu } from 'src/graphql/queries/menu'
import { Container } from 'src/components/department/Container'
import { useMobile } from 'src/hooks/useMobile'
import { useElementOnScreen } from 'src/hooks/useElementOnScreen'
import { sendEvent } from 'src/utils/restructure/analytics'
import { makeViewPromotion } from 'src/utils/restructure/analytics/makeViewPromotion'
import { makePromoViewG3 } from 'src/utils/restructure/analytics/makePromoViewG3'

import type { Sport } from '../FindSport/FindSportModal'
import { AllSportsModal } from '../FindSport/FindSportModal'
import { MainBanners } from './components/MainBanners'
import { SecondaryBanners } from './components/SecondaryBanners'
import { useFindYourSports } from './components/useFindYourSports'
import type { FindYourSports } from './types'
import { sendToDatalayer } from './utils'

export const FindYourSport = (props: FindYourSports) => {
  const { screenWidth } = useMobile()

  const { containerRef, isVisible, hasBeenViewed, setHasBeenViewed } =
    useElementOnScreen({
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

  const { enableSession, mainBanners, secondaryBanners } = props

  const menuData = useMenu()
  const [sports] =
    menuData.allCmsHome?.nodes?.[0]?.sections?.[0]?.data?.departments ??
    menuDataFallback.departments

  const AllSports = (sports?.categories?.find(
    (category) => category.picto === 'Todos os Esportes'
  ) ?? []) as Level2

  const [isOpen, setIsOpen] = useState(false)

  const { validateProps } = useFindYourSports()

  useEffect(() => {
    const banners =
      screenWidth > 900
        ? [...mainBanners, ...secondaryBanners]
        : [...mainBanners]

    if (!isVisible || hasBeenViewed) {
      return
    }

    const promotions = banners.map(({ title, linkUrl, image }, index) => {
      return {
        promotion_id: linkUrl,
        promotion_name: title.text,
        creative_slot: image.desktop,
        index: index + 1,
      }
    })

    sendEvent(makePromoViewG3(promotions))
    sendEvent(makeViewPromotion(promotions))
    setHasBeenViewed(true)
  }, [
    isVisible,
    hasBeenViewed,
    setHasBeenViewed,
    screenWidth,
    mainBanners,
    secondaryBanners,
  ])

  const propsIsValid = validateProps(props)

  if (!propsIsValid) {
    return <></>
  }

  if (!enableSession) {
    return null
  }

  return (
    <Container
      data-testid="section-find-your-sport"
      className="mx-auto w-full max-w-[1280px] relative overflow-hidden
      restructure-small-desktop:[32px] my-[40px] restructure-small-desktop:my-[64px]"
      aria-label="Seção: Encontre seu esporte"
    >
      <div
        ref={containerRef}
        data-testid="title-find-your-sport"
        className="flex flex-row justify-between mb-6 restructure-small-desktop:mb-8 pl-0 restructure-tablet:pl-0
        items-center"
        aria-label="Encontre seu esporte"
      >
        <h2
          data-testid="title-find-your-sport"
          className="mobile-heading-title3 desktop-heading-title3 restructure-tablet:pl-0"
          aria-label="Encontre seu esporte"
        >
          Encontre seu esporte
        </h2>

        <button
          className="hidden restructure-small-desktop:flex font-bold text-restructure-action
          justify-center
          items-center
          desktop-body-semibold-text2
          mobile-body-semibold-text2
          tablet-mobile-body-semibold-text2
          min-h-[48px]
          "
          onClick={() => {
            setIsOpen(true)
            sendToDatalayer('Ver todos os esportes')
          }}
        >
          Ver todos os esportes <PlusIcon />
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className="rounded w-full h-full overflow-hidden
        restructure-small-desktop:max-w-[628px]
        restructure-small-desktop:max-h-[521px]

        restructure-maxMobile:bottom-0
        restructure-maxMobile:top-1/4
        restructure-maxMobile:left-0
        restructure-maxMobile:translate-x-0
        restructure-maxMobile:translate-y-0


        restructure-maxTablet:bottom-0
        restructure-maxTablet:top-1/4
        restructure-maxTablet:left-0
        restructure-maxTablet:translate-x-0
        restructure-maxTablet:translate-y-0"
      >
        <AllSportsModal
          sportsList={
            AllSports?.subcategories?.map((subCategorie) => {
              const { title, url } = subCategorie as Level3SimpleListing

              return { title, url } as Sport
            }) ?? []
          }
          onClose={() => setIsOpen(false)}
        />
      </Modal>
      {mainBanners.length && <MainBanners {...props} />}
      {secondaryBanners.length && <SecondaryBanners {...props} />}

      <div>
        <button
          className="flex restructure-small-desktop:hidden font-bold text-restructure-action
          bg-restructure-background-secondary
        justify-center
        items-center
        rounded-[360px]
        desktop-body-semibold-text2
        mobile-body-semibold-text2
        tablet-mobile-body-semibold-text2
        w-full
        min-h-[48px]
        mt-4
        gap-[8px]
        "
          onClick={() => {
            setIsOpen(true)
            sendToDatalayer('Ver todos os esportes')
          }}
        >
          Ver todos os esportes <PlusIcon width={20} height={20} />
        </button>
      </div>
    </Container>
  )
}
