import { useState } from 'react'
import Section from 'src/components/sections/Section'
import DktSlider from 'src/components/restructure/common/DktSlider/DktSlider'

import type { HeroBannerHome } from './types'
import { Banner } from './Banner'
import { useHeroBanner } from './useHeroBanner'

import './style.scss'

export const HeroBanner = (props: HeroBannerHome) => {
  const { banners, enableSession, section, locationOnPage } = props
  const [slideActive, setSlideActive] = useState(0)

  const { validateProps } = useHeroBanner()

  const propsIsValid = validateProps(props)

  if (!propsIsValid) {
    return null
  }

  if (!enableSession || banners.length < 1) {
    return null
  }

  return (
    <Section className="hero-banner" data-testid="hero-banner">
      {banners.length === 1 ? (
        <Banner
          {...banners[0]}
          index={0}
          section={section}
          locationOnPage={locationOnPage}
        />
      ) : (
        <DktSlider
          currentSlider={(idx) => setSlideActive(idx)}
          sliderId="hero-banner"
        >
          {banners.map((banner, index) => (
            <Banner
              key={banner.image.descImage}
              locationOnPage={locationOnPage}
              section={section}
              index={index}
              inSlide
              slideActive={slideActive === index}
              {...banner}
            />
          ))}
        </DktSlider>
      )}
    </Section>
  )
}
