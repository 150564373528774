import classNames from 'classnames'
import { Link } from 'gatsby'
import { InView } from 'react-intersection-observer'
import { useMobile } from 'src/hooks/useMobile'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { Image } from 'src/components/ui/Image'

import type { BannersStructure, FindYourSports } from '../../types'

export const SecondaryBanners = (props: FindYourSports) => {
  const { section, locationOnPage, secondaryBanners: dataBanners } = props

  const standardObject = getStandardObject()

  const { screenWidth } = useMobile()

  const HandleClick = (bannerName: string, index: number) => {
    if (!locationOnPage || !section) {
      return
    }

    const featureObject = getFeatureObject({
      section,
      locationOnPage,
      name: bannerName ?? '',
    })

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': bannerName ?? '',
        'promotional element index': index + 3,
      },
    })

    const eventTrack = makeEventTrack({
      eventAction: `Encontre seu esporte - card ${bannerName}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
    localStorage.setItem('originViewListImpressionEvent', `${bannerName}`)
  }

  const sendPromoFeatureViewedEvent = (item: BannersStructure) => {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name: item?.image.altImage ?? item.title.text,
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  return (
    <div
      className={classNames(
        'mt-6 gap-3 restructure-small-desktop:gap-6 flex flex-wrap restructure-small-desktop:flex-nowrap'
      )}
      data-testid="section-secondarybanners"
    >
      {dataBanners.map((item, index) => (
        <InView
          as="div"
          key={index}
          threshold={0.7}
          triggerOnce
          onChange={(inView) => {
            if (inView) {
              sendPromoFeatureViewedEvent(item)
            }
          }}
          className={classNames(
            'overflow-hidden cards relative restructure-small-desktop:mb-0 bg-restructure-background-secondary rounded-lg restructure-small-desktop:h-[193px] restructure-small-desktop:w-[193px]',
            {
              'w-[calc(50%-8px)]': screenWidth <= 900,
              'w-full': screenWidth > 900,
            },
            {
              'restructure-small-desktop:max-h-[193px] restructure-small-desktop:min-h-[193px]':
                screenWidth <= 900,
            }
          )}
        >
          <Link
            to={item.linkUrl}
            className="flex h-full flex-col justify-between relative
            restructure-small-desktop:h-[193px]
            restructure-small-desktop:w-[193px]"
            aria-label={`Link para ${item.title.text}`}
            data-testid={`findyoursport-link-item-${index}`}
            onClick={() => HandleClick(item.title.text, index)}
          >
            <h3
              className={classNames(
                'my-4 restructure-small-desktop:mt-4 restructure-small-desktop:mb-6 restructure-medium-desktop:mt-6 restructure-medium-desktop:mb-8 text-center mobile-body-semibold-text3 tablet-mobile-body-semibold-text2 desktop-body-semibold-text2',
                {
                  'text-restructure-primary-inverted':
                    item.title.textColor === 'branco',
                },
                {
                  'text-restructure-primary': item.title.textColor === 'preto',
                }
              )}
            >
              {item.title.text}
            </h3>
            <Image
              loading="lazy"
              className="w-full h-full object-cover absolute hidden restructure-small-desktop:block"
              src={item.image.desktop}
              width={193}
              height={193}
              title={item.title.text}
              alt={item.image.altImage ?? ''}
              data-id={item.title.text}
              data-testid="image-item"
              data-banner-type="Categories Shelf"
            />
          </Link>
        </InView>
      ))}
    </div>
  )
}
