import classNames from 'classnames'
import { Link } from 'gatsby'
import { useMobile } from 'src/hooks/useMobile'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { InView } from 'react-intersection-observer'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import type { BannersStructure, FindYourSports } from '../../types'

export const MainBanners = (props: FindYourSports) => {
  const { section, locationOnPage, mainBanners: dataBanners } = props
  const standardObject = getStandardObject()

  const { screenWidth } = useMobile()

  const HandleClick = (bannerName: string, index: number) => {
    if (!locationOnPage || !section) {
      return
    }

    const featureObject = getFeatureObject({
      section,
      locationOnPage,
      name: bannerName ?? '',
    })

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': bannerName ?? '',
        'promotional element index': index ?? 0,
      },
    })

    const eventTrack = makeEventTrack({
      eventAction: `Encontre seu esporte - banner ${bannerName}`,
      eventPage: 'Home',
    })

    sendEvent(eventTrack)
    localStorage.setItem('originViewListImpressionEvent', `${bannerName}`)
  }

  const sendPromoFeatureViewedEvent = (item: BannersStructure) => {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name: item?.image.altImage ?? item.title.text,
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  return (
    <div
      className="flex gap-6 scrollbar-hidden-v2 pl-0 justify-between overflow-x-auto"
      data-testid="section-mainbanners"
    >
      {Object.values(dataBanners).map((item, index) => (
        <InView
          as="div"
          threshold={0.7}
          triggerOnce
          onChange={(inView) => {
            if (inView) {
              sendPromoFeatureViewedEvent(item)
            }
          }}
          key={index}
          className="relative restructure-maxMobile:min-w-[288px]  w-[288px] h-[145px]
          restructure-tablet:w-[254px]
          restructure-tablet:h-[128px]
          restructure-small-desktop:w-[410px]
          restructure-small-desktop:h-[207px]"
        >
          <Link
            to={item.linkUrl}
            className="block relative w-full h-full rounded-md overflow-hidden"
            aria-label={`Link do banner para ${item.title.text}`}
            data-testid={`findyoursport-link-banner-${index}`}
            onClick={() => HandleClick(item.title.text, index)}
          >
            <img
              src={
                screenWidth < 900
                  ? item.image.tablet
                  : screenWidth < 600
                  ? item.image.mobile
                  : item.image.desktop
              }
              alt={item.image.altImage}
              title={item.title.text}
              data-id={item.title.text}
              className="w-full h-full object-cover"
              data-testid="image-item"
              data-banner-type="Banner Home"
            />
            <h3
              className={classNames(
                'absolute left-3 bottom-3 restructure-small-desktop:left-6 restructure-small-desktop:bottom-6 mobile-body-regular-text1 tablet-mobile-body-regular-text1 desktop-heading-title4',
                {
                  'text-restructure-primary-inverted':
                    item.title.textColor === 'branco',
                },
                {
                  'text-restructure-primary': item.title.textColor === 'preto',
                }
              )}
              data-testid="item-name"
            >
              {item.title.text}
            </h3>
          </Link>
        </InView>
      ))}
    </div>
  )
}

export default MainBanners
