import { useCallback, useRef, useState } from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { Picture } from 'src/components/ui/Image'

import type { HeroBanner } from './types'
import { dispatchToDatalayer } from './utils'

interface Props extends HeroBanner {
  index: number
  inSlide?: boolean
  slideActive?: boolean
  section: string
  locationOnPage: string
}

export const Banner = ({
  title,
  image,
  linkUrl,
  index,
  inSlide = false,
  slideActive = false,
  locationOnPage,
  section,
  text,
}: Props) => {
  const [hasBeenViewed, setHasBeenViewed] = useState(false)
  const [featureViewed, setFeatureViewed] = useState(false)

  const imageRef = useRef<HTMLImageElement | null>(null)

  const { ref } = useInView({
    threshold: 0.7,
    onChange: (inView) => {
      if (inView) {
        handleInview()
      }
    },
    triggerOnce: true,
  })

  const dispatchData = useCallback(() => {
    if (!imageRef.current?.currentSrc || hasBeenViewed) {
      return
    }

    dispatchToDatalayer([
      {
        title,
        currentBannerImage: imageRef.current.currentSrc,
        linkUrl,
        text,
        image,
      },
    ])
    setHasBeenViewed(true)
  }, [title, linkUrl, text, image, hasBeenViewed])

  const sendPromoFeatureViewedEvent = useCallback(() => {
    if (featureViewed || !locationOnPage || !section) {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Promo Feature Viewed',
      eventData: {
        ...getStandardObject(),
        ...getFeatureObject({
          section,
          locationOnPage,
          name: image?.altImage ?? image?.descImage ?? '',
        }),
      },
    })

    setFeatureViewed(true)
  }, [featureViewed, locationOnPage, section, image])

  const handleInview = () => {
    sendPromoFeatureViewedEvent()

    if (!hasBeenViewed && (!inSlide || slideActive)) {
      dispatchData()
    }
  }

  return (
    <div data-index={index} ref={ref}>
      <Link
        to={linkUrl ?? ''}
        title={title?.text}
        className="relative block !p-0"
        data-testid="individual-banner"
        onClick={() =>
          dispatchAmplitudeEvent({
            eventName: 'Promotional Element Selected',
            eventData: {
              ...getStandardObject(),
              ...getFeatureObject({
                section,
                locationOnPage,
                name: image?.altImage ?? '',
              }),
              'promotional element name': image?.descImage ?? '',
              'promotional element index': index ?? 0,
            },
          })
        }
      >
        <Picture
          data-banner-type="Carousel Home"
          sources={[
            {
              src: image?.mobile ?? '',
              width: 360,
              height: 480,
              media: '(max-width: 766px)',
            },
            {
              src: image?.tablet ?? '',
              width: 766,
              height: 409,
              media: '(max-width: 900px)',
            },
            {
              src: image?.desktop ?? '',
              width: 1440,
              height: 623,
              media: '(min-width: 901px)',
            },
          ]}
          img={{
            src: image?.desktop ?? '',
            width: 1440,
            height: 623,
            'data-id': image?.descImage ?? '',
            title: image?.descImage ?? '',
            alt: image?.altImage ?? '',
            loading: 'eager',
            fetchpriority: 'high',
            ref: imageRef,
          }}
        />
      </Link>
    </div>
  )
}
